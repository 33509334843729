<template>
  <div class="textmessage">
    <el-card>
      <div class="global-search-single">
        <div class="single-left">
          <el-button type="primary" @click="edit" v-if="hasPerm(['delivery.rules.store'])">新增运费模板</el-button>
        </div>
        <div class="single-right">
          <div class="search-item">
            <div class="search-item">
              <label class="search-label">搜索模版：</label>
              <el-input type="text" v-model="templateForm.rule_name" placeholder="请输入关键词" />
            </div>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
          </div>
        </div>
      </div>
      <div class="textmessage_bottom">


        <div v-for="(item, index) in templateData" :key="item.id" class="textmessage_bottom_02">
          <div class="textmessage_bottom_02_heard">
            <!-- left -->
            <div class="textmessage_bottom_02_heard_left">
              <div class="default" v-show="item.is_default == 1">默认</div>
              <div class="template-name">
                <span>模板名称：</span>
                <span class="span03">{{ item.rule_name }}</span>
              </div>
              <span class="last-modify-time">最后编辑时间：{{ item.updated_at }}</span>
            </div>
            <!-- right -->
            <div class="textmessage_bottom_02_heard_right">
              <span v-show="item.is_default == 0" @click="handleSetDefault(item)">设为默认</span>
              <span @click="handleModify(item)" v-if="hasPerm(['delivery.rules.update'])">编辑</span>
              <span @click="handleCopy(item)">复制</span>
              <span @click="handleDelete(item)" v-if="hasPerm(['delivery.rules.destroy'])">删除</span>
            </div>
          </div>
          <el-table :data="item.able_delivery_areas">
            <el-table-column prop="date" label="可配送区域" width="50%">
              <template slot-scope="scope">
                {{ addressResourceMap2String(scope.row.province_list) }}
                <!-- {{ scope.row.province_list.map(item => item.name).join(",") }} -->
              </template>
            </el-table-column>
            <el-table-column prop="frist" label="首件(个)" width="10%" align="right"></el-table-column>
            <el-table-column prop="frist_price" label="运费" width="10%" align="right"></el-table-column>
            <el-table-column prop="second" label="续件(个)" width="10%" align="right"></el-table-column>
            <el-table-column prop="second_price" label="续费(元)" width="10%" align="right"></el-table-column>
            <el-table-column prop="starting_delivery_price" label="起送金额" width="10%" align="right"></el-table-column>
          </el-table>
        </div>
        <el-empty description="暂无模板，请先进行添加" v-if="templateData.length === 0"></el-empty>
      </div>
    </el-card>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import common from '../../../common/common';

export default {
  data() {
    return {
      templateData: [],
      templateForm: {
        rule_name: "",//规则名称
      },
      searchObj: {
        search: [],
        order: [
          {
            key: 'id',
            value: 'desc'
          }
        ]
      }
    };
  },
  created() {
    this.getTemplateInfo();
  },
  methods: {
    // 将省市区数据转为地址拼接
    addressResourceMap2String(addArray) {
      return addArray.reduce((pre, item, index) => {
        pre += `${item.name} ${((item.list && item.list.length > 0) && '(' + this.addressResourceMap2String(item.list) + ')') || ""}`
        return pre
      }, "")
    },
    // 获取模版信息
    getTemplateInfo() {
      this.$set(this.searchObj, 'search', this.common.handleSearch(this.templateForm, 'search'));
      this.$get(this.$apis.deliveryRuleApi, { ...this.searchObj }).then(res => {
        if (res.code == 200) {
          this.$set(this, 'templateData', res.data.list)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSearch(event) {
      this.getTemplateInfo();
    },
    // 设置默认规则
    handleSetDefault({ id }) {
      this.$put(`/setting/delivery/${id}/set_default_rules`).then(res => {
        if (res.code == 200) {
          Message.success("设置默认规则成功!");
          // 刷新
          this.getTemplateInfo();
        } else {
          Message.error(res.message);
        }
      })
    },
    // 编辑
    handleModify({ id }) {
      this.$router.push({ path: "/setup/yfmbrout", query: { id } })
    },
    // 复制
    handleCopy({ rule_name, billing_type, able_delivery_areas, disable_delivery_areas }) {
      const newName = common.timeStampTurnTime(new Date(), 'yyyyMMdd') + new Date().getTime().toString().substring(new Date().getTime().toString().length - 6, new Date().getTime().length);
      // 将当前复制的模版数据进行添加即可
      const newObj = {
        rule_name: rule_name.includes(new Date().getFullYear()) ? rule_name.substring(0, rule_name.indexOf(new Date().getFullYear().toString().substring(0, 1))) + newName : rule_name + newName,
        billing_type,
        able_delivery_areas,
        disable_delivery_areas,
      }
      this.$post(this.$apis.deliveryRuleApi, newObj).then(res => {
        if (res.code == 200) {
          Message.success("复制运费模版成功!");
          this.getTemplateInfo();
        } else {
          Message.error(res.message);
        }
      })
    },
    // 删除
    handleDelete({ id, rule_name }) {
      this.$confirm(`确定要将该模版【${rule_name}】永久删除吗？`, "警告", {
        type: "warning"
      }).then(() => {
        this.$delete(`${this.$apis.deliveryRuleApi}/${id}`).then(res => {
          if (res.code == 200) {
            Message.success("删除运费模版成功!");
            this.getTemplateInfo();
          } else {
            Message.error(res.message);
          }
        })
      }).catch(err => {
        Message[((() => err == 'cancel' ? 'info' : 'error'))()]({
          message: err == 'cancel' ? '已取消删除!' : err
        })
      })
    },



    edit() {
      this.$router.push("/setup/yfmbrout")
    },
    fhubtn() {
      this.$router.push("/setup/payment");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  /deep/ .el-empty{
                  width: 100%;
                  margin: 0 auto;
                  .el-empty__image{
                          width: 150px;
                          margin: 0 auto;
                  }
                  .el-empty__description{
                          font-size: 14px;
                          margin-top: 10px;
                          text-align: center;
                  }
        }
  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding-top: 24px;
    box-sizing: border-box;

    .textmessage_bottom_01 {
      width: 100%;
      height: 62px;
      display: flex;
      justify-content: space-between;

      // margin-bottom: -33px;
      & /deep/.el-form-item__label {
        width: 70px;
        // height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        // line-height: 16px;
      }

      & /deep/.el-input__inner {
        width: 240px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #DDDFE6;
      }
    }

    .textmessage_bottom_02 {
      width: 100%;

      .textmessage_bottom_02_heard {
        width: 100%;
        height: 50px;
        background: #f9f9fc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;
        box-sizing: border-box;

        .textmessage_bottom_02_heard_left {
          line-height: 50px;
          display: flex;
          align-items: center;


          & .default {
            width: 38px;
            height: 22px;
            text-align: center;
            line-height: 18px;
            padding: 2px 7px;
            box-sizing: border-box;
            background: #FEF3EE;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fb784a;
            margin-right: 8px;
          }

          & .template-name {
            & span:nth-of-type(1) {
              display: inline-block;
              // width: 70px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 20px;
            }

            & span:nth-of-type(2) {
              display: inline-block;
              // width: 70px;
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }

          }

          & .last-modify-time {
            // width: 222px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-left: 32px;
          }

          .span03 {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }

        .textmessage_bottom_02_heard_right {
          width: auto;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--fontColor);
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & span {
            margin-right: 13px;

            &:last-child {
              margin-right: 0;
            }
          }



          span {
            display: inline-block;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: var(--fontColor);
            cursor: pointer;
          }
        }
      }

      & /deep/.el-table {
        & .el-table__header {
          width: 100% !important;

          & tr {
            width: 100%;

            & .cell {
              width: 100% !important;
              padding: 0 16px;
              box-sizing: border-box;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
            }
          }
        }

        & .el-table__body {
          width: 100% !important;

          & .cell {
            width: 100% !important;
            padding: 0 16px;
            box-sizing: border-box;
          }
        }

        & th.el-table__cell {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          background-color: #fff;

          &:nth-of-type(2) {
            height: 56px;
          }
        }

        & td.el-table__cell {
          &:nth-of-type(2) {
            height: 76px;
          }
        }
      }
    }

    .goodsbtn {
      width: 116px;
      height: 38px;
      background: #000D9A;
      border-radius: 4px;
      text-align: center;
      padding: 0;
    }

    .bottom-bat {
      .bottom-bat-tab {
        height: 50px;
        background: #f9f9fc;
      }
    }
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}
</style>